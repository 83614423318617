import React, { useContext, useEffect, useState } from 'react';
import { ColorPicker, useColor } from "react-color-palette";
import { StyleContext } from './StyleContext';
import "react-color-palette/css";
import fullscreenIcon from './fullscreen.svg';
import settingsIcon from './settings.svg';
import closeIcon from './close.svg';
import DonateButton from './DonateButton';

const sidebarWidth = 280;
const defaultColor = "#561ecb";

export const Sidebar = () => {
  const { styleSettings, updateStyle } = useContext(StyleContext);
  const [color, setColor] = useColor(defaultColor);
  const [open, setOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const handleStyle = (newColor) => {
    setColor(newColor);
    updateStyle({ ...styleSettings, backgroundColor: newColor.hex });
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Initialize default color
    updateStyle({ ...styleSettings, backgroundColor: defaultColor });

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <>
      {open && (
        <div
          className='fixed inset-0 z-20'
          style={{ backgroundColor: 'rgba(0,0,0,0)' }}
          onClick={() => setOpen(false)}
        />
      )}
      <div className={fullscreen ? 'hidden' : ''}>
        <div
          className='w-10 h-10 drop-shadow-md rounded-full absolute z-30 top-3 left-3 border-2 border-white bg-white bg-opacity-20 cursor-pointer flex justify-center items-center'
          onClick={() => setOpen(!open)}
        >
          <img src={settingsIcon} alt="Settings" />
        </div>
        <div
          className='w-10 h-10 drop-shadow-md rounded-full absolute z-30 top-3 right-3 border-2 border-white bg-white bg-opacity-20 cursor-pointer flex justify-center items-center'
          onClick={toggleFullScreen}
        >
          <img src={fullscreenIcon} alt="Fullscreen" />
        </div>
      </div>
      <div
        style={{ left: open ? 0 : `-${sidebarWidth}px`, width: `${sidebarWidth}px` }}
        className={`fixed top-0 bottom-0 w-[${sidebarWidth}px] bg-black p-5 z-30 transition-all`}>
          <h1 className="text-white text-md font-bold mb-5 uppercase flex justify-between items-center">
            Light settings
            <span className="cursor-pointer" onClick={() => setOpen(!open)}>
              <img src={closeIcon} alt="Close" />
            </span>
          </h1>
          <ColorPicker color={color} onChange={handleStyle} />
          <h1 className="text-white text-md font-bold mb-2 mt-4 uppercase text-center">
            Support this project
          </h1>
          <div className='flex justify-center items-center'>
            <DonateButton />
          </div>
          <p className='text-sm bg-zinc-900 text-zinc-500 rounded-3xl px-3 py-1 font-semibold mt-5'>
            Patreon link comming soon...
          </p>
      </div>
    </>
  );
};
