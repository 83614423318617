import React, { useContext } from 'react';
import { StyleContext } from './StyleContext';

export const LightDisplay = () => {
    const { styleSettings } = useContext(StyleContext);

    return (
        <div className='fixed top-0 left-0 right-0 bottom-0 z-10' style={styleSettings}>
        </div>
    );
};
