import React from 'react';
import { StyleProvider } from './StyleContext';
import { Sidebar } from './Sidebar';
import { LightDisplay } from './LightDisplay';

const App = () => {
  return (
      <StyleProvider>
        <Sidebar />
        <LightDisplay />
      </StyleProvider>
    );
  };

export default App;
