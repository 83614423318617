import React, { createContext, useState } from 'react';

// Create a Context
export const StyleContext = createContext();

// Define a Context Provider
export const StyleProvider = ({ children }) => {
    const [styleSettings, setStyleSettings] = useState({backgroundColor: 'white'});

    const updateStyle = (newSettings) => {
        setStyleSettings(newSettings);
    };

    return (
        <StyleContext.Provider value={{ styleSettings, updateStyle }}>
            {children}
        </StyleContext.Provider>
    );
};
